export const youtubeVideosProvider = async () => {
  try {
    const response = await fetch(
      'https://umiya-bakery-videos-json.subscription-viral.workers.dev'
    )
    const videoIds = await response.json()
    return videoIds.map(({ videoId }) => videoId)
  } catch {
    return []
  }
}
