import styled from 'styled-components'
import PageTitle from '../components/PageTitle'
import { useEffect, useState } from 'react'
import { youtubeVideosProvider } from '../utils/youtubeVideosProvider'

const VideosWrapper = styled.div`
  padding: 30px;

  .youtube-video-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    padding: 16px;
  }

  .video-item {
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    border-radius: 5px;
    overflow: hidden;
  }

  .video-item iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const Videos = () => {
  const [videos, setVideos] = useState([])

  useEffect(() => {
    ;(async () => {
      const videos = await youtubeVideosProvider()
      setVideos(videos ?? [])
    })()
  }, [setVideos])

  return (
    <VideosWrapper id="videos-container">
      <PageTitle title="Videos" />
      <div className="youtube-video-container">
        {videos.length > 0 ? (
          videos.map((video, index) => (
            <div key={index} className="video-item">
              <iframe
                src={`https://www.youtube.com/embed/${video}`}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
                title="video"
              />
            </div>
          ))
        ) : (
          <center>
            <h3> Coming soon... </h3>
          </center>
        )}
      </div>
    </VideosWrapper>
  )
}
